import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Book from '../../img/home/book.png'
import Pen from '../../img/home/pen.png'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  school: {
    backgroundColor: '#484250'
  },
  skills: {
    backgroundColor: '#AB9696'
  }

}));

const Info = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={6} className={classes.school}>
            <Grid container style={{ padding: '2rem' }}>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <img src={Pen} style={{ width: '80%' }} alt="" />
                  </Grid>
                  <Grid item xs={12}>

                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <img src={Book} style={{ width: '80%' }} alt="" />
                  </Grid>
                  <Grid item xs={12}>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
        <Grid item xs={6} className={classes.skills}>
            
        </Grid>
      </Grid>
    </div>
  )
}

export default Info