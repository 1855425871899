// import './App.css';
// import './Desk.css';
import './style.css'
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navigation/Navbar'
import Footer from './components/navigation/Footer'
import Home from './views/Home';
// import About from './views/About';
// import ProjectsCode from './views/ProjectsCode';
// import ProjectsArt from './views/ProjectsArt';
import { ReactComponent as Logo } from './img/logo/logo-light.svg'
// import { ReactComponent as Logo } from './img/logo/logo-white.svg'
import NotFound from './views/NotFound'
import { unstable_createMuiStrictModeTheme as createTheme , ThemeProvider, makeStyles } from '@material-ui/core/styles';


const About = lazy(() => {
  const x = new Promise((resolve) => {
    setTimeout(() => {
      return resolve(import('./views/About'))
    }, 1000)
  })
  return x;
})
const ProjectsCode = lazy(() => {
  const x = new Promise((resolve) => {
    setTimeout(() => {
      return resolve(import('./views/ProjectsCode'))
    }, 1000)
  })
  return x;
})
const ProjectsArt = lazy(() => {
  const x = new Promise((resolve) => {
    setTimeout(() => {
      return resolve(import('./views/ProjectsArt'))
    }, 1000)
  })
  return x;
})


// const About = lazy(() => import('./views/About'));
// const ProjectsCode = lazy(() => import('./views/ProjectsCode'));
// const ProjectsArt = lazy(() => import('./views/ProjectsArt'));

const theme = createTheme({
  palette: {
    primary: {
      // main: "#A78FD6"
      // main: "#6247AA"
      main: "#7966AA"
    },
    secondary: {
      main: "#fcfcfc"
      // main: "#588b8b"
      // main: "#9347AA"
    }
  }
});
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'Calc(100vh - 50px)',
    paddingTop: '50px',
    color: '#A78FD6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%'
    // },
    // [theme.breakpoints.up('md')]: {
    //   width: '90%'
    // },
    // [theme.breakpoints.up('lg')]: {
    //   width: '86%'
    // },
  },



}));

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename='/'>
      <Navbar />
      <Suspense fallback={<div className={classes.root}><Logo className='logospin' /> </div>}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/kodprojekt" element={<ProjectsCode />} />
          <Route exact path="/designprojekt" element={<ProjectsArt />} />
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  </ThemeProvider>
  );
}

export default App;
