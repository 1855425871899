import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Eyes from '../../img/animation/blink/eye-base-new-2.svg';
// import Eyes from '../../img/animation/blink/eye-base-new.svg';
import LashesUp from '../../img/animation/blink/lashes-up-new.svg';
import LashesDown from '../../img/animation/blink/lashes-down-new.svg';
// import LashesUp from '../../img/animation/blink/lashes-up.svg';
// import LashesDown from '../../img/animation/blink/lashes-down.svg';
import Lips from '../../img/animation/blink/lips2.svg';
import { ReactComponent as Logo } from '../../img/logo/logo-white.svg';
import { ReactComponent as Logo2 } from '../../img/logo/full-mockup-light.svg';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Blobb from '../../img/home/bg-bobble.png';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    transition: 'all 0.3s linear',
    '&:hover': {
      // opacity: '0.5',
      color: '#A78FD6',
      transform: 'rotate(-30deg)'
    },
  },
}))(IconButton);

const useStyles = makeStyles((theme) => ({
  root: {

    [theme.breakpoints.down('sm')]: {

    },
    [theme.breakpoints.up('md')]: {
      height: '500px',
      // height: '50vh',
      marginTop: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '420px',
      // height: '50vh',
      marginTop: '10px'
    },
    [theme.breakpoints.up('xl')]: {
      height: '650px',
      marginTop: '50px'
    },
  },
  headerWidth: {
    // width: '80%',
    // height: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
  },
  logo: {
    // width: '80%',
    
    [theme.breakpoints.down('sm')]: {
      // width: '50px',
      height: '50px'
    },
    [theme.breakpoints.up('sm')]: {
      // width: '70px',
      height: '70px'
    },
    [theme.breakpoints.up('md')]: {
      // width: '80px',
      height: '80px'
    },
    [theme.breakpoints.up('lg')]: {
      // width: 'unset',
      height: '110px'
    },
    [theme.breakpoints.up('xl')]: {
      // width: 'unset',
      height: '150px'
    },
  },
  BlinkContainer: {
    // width: '80%',    
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      width: '80px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100px'

    },
    [theme.breakpoints.up('xl')]: {
      width: '100px'
    },

  },
  headerText: {
    // width: '80%',
    [theme.breakpoints.down('sm')]: {
      '& h1': {
        fontSize: 24, 
      },
      '& div': {
        fontSize: 18, 
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& h1': {
        fontSize: 35, 
      },
      '& div': {
        fontSize: 28, 
      },
    },
    [theme.breakpoints.up('md')]: {
      '& h1': {
        fontSize: 30, 
      },
      '& div': {
        fontSize: 20, 
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& h1': {
        fontSize: 44, 
      },
      '& div': {
        fontSize: 25, 
      },
    },
    [theme.breakpoints.up('xl')]: {
      '& h1': {
        fontSize: 46, 
      },
      '& div': {
        fontSize: 25, 
      },
    },
  },
  blobb: {
    // backgroundImage: `url(${Blobb})`,
    position: 'absolute',
    // backgroundPosition: 'bottom',
    // backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    bottom: '-20vh',
    zIndex: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '100%',
    },

  },
  h5S: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    },
  }
  
}));

const Header = () => {
  const classes = useStyles();
  const [state, setState] = React.useState(false)

  // const ball1 = document.getElementById('ball1');
  // const ball2 = document.getElementById('ball2');
  // const lips = document.getElementById('lips');

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    // window.onload = (event) => {
    //   console.log('page is fully loaded');
      // setLoading(true)
      const ball1 = document.getElementById('ball1');
      const ball2 = document.getElementById('ball2');
      const lips = document.getElementById('lips');
      document.onmousemove = function(e) {
      
        if(ball1 !== null && ball2 !== null) {
  
          const x = -(window.innerWidth / 2 - e.pageX) / 110;
          const y = -(window.innerHeight / 2 - e.pageY) / 110;
  
          ball1.style.transform = `translateY(${y}px) translateX(${x}px)`;
          ball2.style.transform = `translateY(${y}px) translateX(${x}px)`;
  
          // ball1.style.transform = `translateY(${y}) translateX(${x})`;
          // ball2.style.transform = `translateY(${y}) translateX(${x})`;
    
        }
        if(lips !== null) {
    
          const x = -(window.innerWidth / 2 - e.pageX) / 330;
          // const z = -(window.innerWidth / 2 - e.pageX) / 180;
          const y = -(window.innerHeight / 2 - e.pageY) / 280;
    
          lips.style.transform = `rotate(${y}deg) rotate(${x}deg) skew(${x}deg) skew(${y}deg)`;
        }
    
      }

      // console.log(ball1)
    // };

    // console.log('hejhej')
  })



  return (
    <div style={{ height: '100%', position: 'relative', zIndex: '1'}}>
      {/* <div className={classes.blobb}></div> */}
      <img src={Blobb} className={classes.blobb} alt="" />
      <div className='container'>
      <Grid container alignItems='center' justifyContent='space-between' className={classes.root}>
        <Grid item xs={12} md={6} lg={6} >
          
          <Fade left duration={1000}>
          <Grid container style={{ width: '80%' }} className="m-auto">

              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Bounce  duration={2000} spy={state} appear={true}>
                  <div onMouseEnter={() => setState(!state)} style={{marginRight: '1rem', color: '#A78FD6' }}  >
                    <Logo2 className={classes.logo} />
                  </div>
                </Bounce>
                <div className={`mt-3 mb-2 ${classes.headerText}`}>
                  {/* <h1 style={{ margin: '0px', fontWeight: '400', letterSpacing: '7px', color: '#fff', whiteSpace: 'nowrap' }}>Sanne Sidell. </h1> */}
                  <div style={{ letterSpacing: '2px', color: '#fff', opacity: '0.8' }}>Frontend-utvecklare</div>
                </div>
              </Grid>
              {/* <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Bounce  duration={2000} spy={state} appear={true}>
                  <div onMouseEnter={() => setState(!state)} style={{marginRight: '1rem', color: '#A78FD6' }}  >
                    <Logo className={classes.logo} />
                  </div>
                </Bounce>
                <div className={classes.headerText}>
                  <h1 style={{ margin: '0px', fontWeight: '400', letterSpacing: '7px', color: '#fff', whiteSpace: 'nowrap' }}>Sanne Sidell. </h1>
                  <div style={{ letterSpacing: '2px', color: '#fff', opacity: '0.6' }}>Frontend-utvecklare</div>
                </div>
              </Grid> */}
              {/* <Grid item xs={12}>
                <h5 className={`my-3 ${classes.h5S}`} style={{ fontWeight: '400' }}>Min passion är interaktiv design. Drivs av nyfikenhet och problemlösning. Min avslappning är kreativitet. </h5>
              </Grid> */}
              <Grid item xs={12} className="d-flex align-items-center justify-content-center">
                <ColorButton  href="https://github.com/Sharpi3s" target="_blank" rel="noopener noreferrer">
                  <GitHubIcon style={{ fontSize: 40 }}/>
                </ColorButton>
                <ColorButton  href="https://se.linkedin.com/in/sanne-sidell" target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon style={{ fontSize: 40 }}/>
                </ColorButton>
              </Grid>
          </Grid>
          </Fade>
{/* 
          <Grid container className={classes.root} >
            <Fade left duration={1000}>
            <div  className={classes.headerWidth}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Bounce  duration={2000} spy={state} appear={true}>
                  <div onMouseEnter={() => setState(!state)} style={{marginRight: '1rem', color: '#A78FD6' }}  >
                    <Logo className={classes.logo} />
                  </div>
                </Bounce>
                <div className={classes.headerText}>
                  <h1 style={{ margin: '0px', fontWeight: '400', letterSpacing: '7px', color: '#fff' }}>Sanne Sidell. </h1>
                  <div style={{ letterSpacing: '2px', color: '#fff', opacity: '0.6' }}>Frontend-utvecklare</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <h5 className={`my-3 ${classes.h5S}`} style={{ fontWeight: '400' }}>Min passion är interaktiv design. Drivs av nyfikenhet och problemlösning. Min avslappning är kreativitet. </h5>
              </Grid>
              <Grid item xs={10} style={{ textAlign: 'center' }}>
              <ColorButton  href="https://github.com/Sharpi3s" target="_blank" rel="noopener noreferrer">
                <GitHubIcon style={{ fontSize: 40 }}/>
              </ColorButton>
              <ColorButton  href="https://se.linkedin.com/in/sanne-sidell" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon style={{ fontSize: 40 }}/>
              </ColorButton>
              </Grid>
            </div>
            </Fade>
            <div className='bg-header'></div>
          </Grid> */}

        </Grid>
        <Grid item xs={12} md={5} lg={6} className={`d-flex justify-content-md-center justify-content-lg-start ${classes.BlinkContainer}`}>
        <div className='bg-header'></div>
          <Zoom bottom duration={1000} delay={500}>
          <div className="blink" >
            <div className="open-eyes">
              <div id="pupils">
                <div id="pupil1">
                  <div id="ball1"></div>
                </div>
                <div id="pupil2">
                  <div id="ball2"></div>
                </div>  
              </div>
              
              <img id="eyes" src={Eyes} alt="" />
              <img id="lashes-up" src={LashesUp} alt="" />
              <img id="lashes-down" src={LashesDown} alt="" />
            </div>
            <img id="lips" src={Lips} alt="" />
          </div>
          </Zoom>
        </Grid>
      </Grid>
    </div>

    </div>
  )
}

export default Header