import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Laptop from '../../img/home/laptopcode.svg'
import iPad from '../../img/home/graphicdesign.svg'
import AvatarIcon from '../../img/home/icon-face.svg'
// import Laptop from '../../img/home/Layer_5.png'
// import iPad from '../../img/home/Layer_3.png'
// import AvatarIcon from '../../img/home/AvatarIcon.png'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    
    // backgroundColor: 'transparent',
    backgroundColor: 'rgba(41,42,48,0.3)', 
    // backgroundColor: '#ffffff1a', 
    backdropFilter: 'blur(14px)',  
    padding: '1.5rem', 
    // border: '1px solid #ffffff33', 
    color: '#fff',
    // height: 230,
    boxShadow: '0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07)',

    transition: 'all 0.4s linear',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
      // height: 230,
      // height: '245px', 
    },
    [theme.breakpoints.up('sm')]: {

    },
    [theme.breakpoints.up('md')]: {

    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '255px',
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: '245px',
    },
  },
  cardContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent:'center', 
      alignItems:'center'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent:'space-between', 
      alignItems:'flex-start'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent:'space-between', 
      alignItems:'flex-start',
    },
  },
  marginTopCards: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '100px'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '20px'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-70px'
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '-100px'
    },
  },
  media: {
    // width: '50%',
    height: 70,
    transition: 'opacity 0.4s linear',
  },
  Btn: {
    height: '25px',
    position: 'relative',
    backgroundColor: '#fff',
    // width: '30%', 
    transition: 'all 0.4s linear',
    fontWeight: '700',
    marginLeft: '1rem',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#7966AA',
      // backgroundColor: '#A78FD6',
      color: '#fff'
      // filter: 'brightness(1.2)'
    }
  },
  iconBox: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
    // width: '100%'
  },
  icon: {
    
    position: 'absolute',
    '-webkit-transition': 'all 0.4s linear',
    '-webkit-transition': '-webkit-transform all 0.4s linear',
    transition: 'all 0.4s linear',
    [theme.breakpoints.down('sm')]: {
      height: '70px',
    },
    [theme.breakpoints.up('md')]: {
      height: '90px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '100px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '120px',
    },
    // top: '-5rem',
  }

}));

const CardsMobile = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    hoverLaptop: false,
    hoverIPad: false,
    hoverAvatar: false
  });
  return (
    <div className='container' style={{ position: 'relative', zIndex: '5' }}>
      
      <div className={classes.marginTopCards} >
        <Grid container spacing={3} className={classes.cardContainer}>
          <Grid item xs={12} md={4} style={{ height: '100%' }}>
            <Paper className={classes.root} 
            // <Paper className={classes.root} style={{ marginTop: state.hoverLaptop ? '-1.5rem' : '0rem' }}
              onMouseEnter={() => setState({
                ...state,
                hoverLaptop: true,
                hoverAvatar: false,
                hoverIPad: false,
              })} 
              onMouseLeave={() => setState({
                ...state,
                hoverLaptop: false,
              })} 
            >

              <Grid container alignItems='flex-end'>
                <Grid item xs={12} className={classes.iconBox}>
                  {/* <img className={classes.icon} src={Laptop} alt="" style={{ top: state.hoverLaptop ? '24px' : '0', filter: state.hoverLaptop ? 'drop-shadow(0 15px 6px #0000004d)' : 'none' }} /> */}
                  <img className={classes.icon} src={Laptop} alt="" style={{ top: '-3.5rem', transform: state.hoverLaptop ? 'translate(calc(-100% + 20px), 0)' : 'translate(0%, 0)', filter: state.hoverLaptop ? 'drop-shadow(0 15px 6px #0000004d)' : 'none' }} />
                </Grid>

                <Grid item xs={12} style={{ opacity: state.hoverLaptop ? '0.5' : '1', transition: 'opacity 0.4s linear' }} className="mt-3 pb-2">
                  <h5 className='my-3'>Portfolio Kod</h5>
                  <p style={{ color: '#ffffffcc', marginBottom: '0px' }}>
                    Här har jag samlat projekt som jag skapat under min utbildning. Jag har använt mig av bla. React, Material Design, Firebase mm.  
                  </p>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
                  <Link to="/kodprojekt" style={{ textDecoration: 'none' }}>
                    <Button variant='contained' className={classes.Btn} style={{ width: state.hoverLaptop ? '125px' : '10px', padding: state.hoverLaptop ? '2px 2px' : '2px 2px' }}>
                      {state.hoverLaptop ? 
                      <span className='btntext' style={{ position: 'absolute', top: '2px' }}></span> 
                      : <ArrowForwardIosIcon style={{ fontSize: 16 }}/>}
                    </Button>
                  </Link>
                </Grid>

              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} style={{ height: '100%' }} className="mt-4">
          <Paper className={classes.root}
              onMouseEnter={() => setState({
                ...state,
                hoverIPad: true,
                hoverLaptop: false,
                hoverAvatar: false,
              })} 
              onMouseLeave={() => setState({
                ...state,
                hoverIPad: false,
              })} 
          >
              <Grid container alignItems='flex-end'>
                <Grid item xs={12} className={classes.iconBox}>
                <img className={classes.icon} src={iPad} alt="" style={{ top: '-3.5rem', transform: state.hoverIPad ? 'translate(calc(-100% + 20px), 0)' : 'translate(0%, 0)', filter: state.hoverIPad ? 'drop-shadow(0 15px 6px #0000004d)' : 'none'  }} />
                  {/* <img className={classes.icon} src={Laptop} alt="" style={{ opacity: state.hoverLaptop ? '0.5' : '1' }} /> */}
                </Grid>
                <Grid item xs={12} style={{ opacity: state.hoverIPad ? '0.5' : '1', transition: 'opacity 0.4s linear' }} className="mt-3 pb-2">
                  <h5 className='my-3'>Portfolio Design</h5>
                  {/* <p style={{ color: '#ffffffcc', marginBottom: '0px' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi debitis veniam officia hic amet quod quam quidem atque laudantium necessitatibus!</p> */}
                  <p style={{ color: '#ffffffcc', marginBottom: '0px' }}>
                    På denna sida har jag samlat mina digitala alster. Jag har använt mig av allt från Adobe Xd, Illustrator, Procreate, Nomad Sculpt(3D).
                  </p>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
                  <Link to="/designprojekt" style={{ textDecoration: 'none' }}>
                    <Button variant='contained' className={classes.Btn} style={{ width: state.hoverIPad ? '125px' : '10px', padding: state.hoverIPad ? '2px 2px' : '2px 2px' }}>
                      {state.hoverIPad ? 
                      <span className='btntext' style={{ position: 'absolute', top: '2px' }}></span> 
                      : <ArrowForwardIosIcon style={{ fontSize: 16 }}/>}
                    </Button>
                  </Link>
                </Grid>

              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} style={{ height: '100%' }} className="mt-4">
          <Paper className={classes.root}
            onMouseEnter={() => setState({
              ...state,
              hoverAvatar: true,
              hoverIPad: false,
              hoverLaptop: false,
            })} 
            onMouseLeave={() => setState({
              ...state,
              hoverAvatar: false,
            })} 
          >
              <Grid container >
                <Grid item xs={12} className={classes.iconBox}>
                  <img className={classes.icon} src={AvatarIcon} alt="" style={{ top:'-3.5rem', transform: state.hoverAvatar ? 'translate(calc(-100% + 20px), 0)' : 'translate(0%, 0)', filter: state.hoverAvatar ? 'drop-shadow(0 15px 6px #0000004d)' : 'none', overflowX: 'hidden' }}  />
                </Grid>
                <Grid item xs={12} style={{ opacity: state.hoverAvatar ? '0.5' : '1', transition: 'opacity 0.4s linear' }} className="mt-3 pb-2">
                  <h5 className='my-3'>Mer om mig</h5>
                  <p style={{ color: '#ffffffcc', marginBottom: '0px' }}>
                    Vill du veta mer om mig, kontaktuppgifter eller bara utforska sidan så klicka in dig här 👋
                    {/* <span className="waving-hand">👋</span> */}
                  </p>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
                  <Link to="/about" style={{ textDecoration: 'none' }}>
                    <Button variant='contained' className={classes.Btn} style={{ width: state.hoverAvatar ? '125px' : '10px', padding: state.hoverAvatar ? '3px 2px' : '2px 2px' }}>
                      {state.hoverAvatar ? 
                      <span className='btntext' style={{ position: 'absolute', top: '2px' }}></span> 
                      : <ArrowForwardIosIcon style={{ fontSize: 16 }}/>}
                    </Button>
                  </Link>
                </Grid>

              </Grid>
            </Paper>
          </Grid>
        </Grid>

      </div>
    </div>
  )
}

export default CardsMobile