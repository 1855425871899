import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50px',
    color: '#fcfcfc'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },

}));



const Footer = () => {
  const classes = useStyles();
  return (
    <AppBar position="static" style={{ backgroundColor: 'rgba(41,42,48,.9)' }} className={classes.appBar}>
    <Toolbar className={classes.root}>
      <IconButton href="https://github.com/Sharpi3s" target="_blank" rel="noopener noreferrer" color="inherit">
        <GitHubIcon />
      </IconButton>
      <IconButton href="https://se.linkedin.com/in/sanne-sidell" target="_blank" rel="noopener noreferrer" color="inherit">
        <LinkedInIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
  )
}

export default Footer