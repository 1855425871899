import React from 'react'
import Desktop from '../img/home/deskdesk4.png'
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/home/Header';
import Cards from '../components/home/Cards';
import Divider from '@material-ui/core/Divider';
import Info from '../components/home/Info';
import Desk from '../components/home/Desk';
import Blobb from '../img/home/bg-bobble.png';
import CardsMobile from '../components/home/CardsMobile';
import HeaderMobile from '../components/home/HeaderMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#31303B',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '600px',
      width: '100%',
      paddingTop: '50px'
    },
    [theme.breakpoints.up('sm')]: {
      height: '600px',
      width: '100%',
      paddingTop: '80px'
    },
    [theme.breakpoints.up('md')]: {
      height: '75vh',
      width: '100%',
      paddingTop: '80px' 
    },
    [theme.breakpoints.up('lg')]: {
      height: '600px',
      // height: '75vh',
      width: '100%',
      paddingTop: '70px'
    },
    [theme.breakpoints.up('xl')]: {
      height: '700px',
      // height: '75vh',
      width: '100%',
      paddingTop: '0px'
    },
    // paddingTop: '50px'
  },
  bgCards: {
    marginTop: '-10px',
    height: '110px',
    backgroundColor: '#484250'
  },
  dividerBox: {
    [theme.breakpoints.down('sm')]: {
      height: '0vh',
    },
    [theme.breakpoints.up('md')]: {
      height: '5vh',
    },
    [theme.breakpoints.up('lg')]: {
      height: '80px',
      // height: '10vh',
    },
    [theme.breakpoints.up('xl')]: {
      height: '140px',
      // height: '15vh',
    },
  },
  heightHome: {
    minHeight: 'calc(100vh - 50px)'
  }



}));
const Home = () => {
  // const [play, setPlay] = React.useState(false);
  const classes = useStyles();

  return (
    <div  style={{ color: '#fff' }} className={classes.heightHome}>
      <div className={`d-none d-md-block ${classes.root}`}>
        <Header />
      </div>
      <div className={`d-md-none ${classes.root}`}>
        <HeaderMobile />
      </div>
      {/* <div style={{ height: '10vh' }}></div> */}
      {/* <div className={classes.bgCards}></div> */}
      {/* <img style={{ height: '100vh' }} src={Desktop} alt="" /> */}
      <div className='d-none d-lg-block'>
        <Cards /> 
      </div>
      <div className='d-lg-none'>
        <CardsMobile />
      </div>
      <div className={classes.dividerBox}></div>
      {/* <VideoPlayer /> */}
      {/* <Divider style={{ width: '80%', backgroundColor: '#ffffff', opacity: '0.3', margin: 'auto' }}/> */}

      <div className='container'>
        {/* <Info /> */}
        <Desk />
      </div>
      <div style={{ height: '10vh' }}></div>
    </div>
  )
}

export default Home