import React from 'react'
import Grid from '@material-ui/core/Grid';

import SteemR2 from '../../img/animation/desk/steem-r-2.svg'
import SteemR1 from '../../img/animation/desk/steem-r-1.svg'
import SteemMid from '../../img/animation/desk/steem-mid.svg'
import SteemL2 from '../../img/animation/desk/steem-l-2.svg'
import SteemL1 from '../../img/animation/desk/steem-l-1.svg'

import MugFront from '../../img/animation/desk/mug-fronttest.svg'
import Maps from '../../img/animation/desk/maps.svg'
import CodeGroup from '../../img/animation/desk/code-group.svg'
import CodeLineBase from '../../img/animation/desk/code-line-base.svg'

import CodeLine from '../../img/animation/desk/code-line-2.svg'
import BlinkLine from '../../img/animation/desk/blink-line.svg'
import Icons from './Icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  deskBox: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    [theme.breakpoints.up('lg')]: {
      // display: 'flex',
      // alignItems:'center',
      // justifyContent:'center',
    },
  },


}));

const Desk = () => {
  const classes = useStyles();
  return (
    <Grid container >
      <Grid item lg={1}></Grid>
      <Grid item xs={12} md={6} lg={6} className={classes.deskBox}>
        <div className="desk-box">
          <div className="desk">
            <div id="steem">
              <div id="steem-box">
                <img id="s-1" src={SteemR2} alt="" />
                <img id="s-2" src={SteemR1} alt="" />
                <img id="s-3" src={SteemMid} alt="" />
                <img id="s-4" src={SteemL2} alt="" />
                <img id="s-5" src={SteemL1} alt="" />
              </div>
            </div>
            <div id="mug-holder"><img id="mug" src={MugFront} alt="" /></div>
            
            <img id="maps" src={Maps} alt="" />
            <img id="code-base" src={CodeGroup} alt="" />
            <img id="code-line-base" src={CodeLineBase} alt="" />
            <div id="code-box">
              <div id="line-box">
                <img id="code-line" src={CodeLine} alt="" />
              </div>
              <div id="blink-line-box">
                <img id="blink-line" src={BlinkLine} alt="" />
              </div>
            </div>

          </div>
        </div>
      </Grid>
      {/* <Grid item lg={1}></Grid> */}
      <Grid item xs={12} md={6} lg={4} xl={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} className="mt-4 mt-lg-0">
        <div><h3 style={{ color: '#A78FD6', textAlign: 'center', marginBottom: '2rem' }}>Verktyg / Språk / Bibliotek</h3></div>
        <Icons />
      </Grid>
    </Grid>
  )
}

export default Desk