import React from 'react'
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const LTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: '3px'
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize:'2rem',
    
    '& i': {
      transition: '0.5s all ease-out',
      filter: 'brightness(0%) invert(100%)',
      '&:hover': {
        filter: 'brightness(100%) invert(0%)',
        // transition: 'all 2s',
      },
    },

    [theme.breakpoints.down('sm')]: {
      fontSize:'2rem',
      width: '95%',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('md')]: {
      fontSize:'2rem',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize:'2.5rem',
      justifyContent: 'flex-end'

    },
    [theme.breakpoints.up('xl')]: {
      fontSize:'3rem',
      justifyContent: 'flex-end'

    },
  },
  // hoverIcon: {
  //   filter: 'brightness(0) invert(1)',
  //   '&:hover': {
  //     filter: 'brightness(1) invert(0)',
  //   },
  // }
  
}));
const Icons = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item>
      <LTooltip title="JavaScript" placement="bottom">
        <i className="devicon-javascript-plain colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="TypeScript" placement="bottom">
        <i className="devicon-typescript-plain colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="HTML5" placement="bottom">
        <i className="devicon-html5-plain colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="CSS3" placement="bottom">
        <i className="devicon-css3-plain colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Sass" placement="bottom">
        <i className="devicon-sass-original colored"></i>
      </LTooltip>
      </Grid>    

      <Grid item>
      <LTooltip title="React" placement="bottom">
        <i className="devicon-react-original colored"></i>
      </LTooltip>
      </Grid>

      <Grid item>
      <LTooltip title="React-redux" placement="bottom">
        <i className="devicon-redux-original colored"></i>
      </LTooltip>
      </Grid>
      
      <Grid item>
      <LTooltip title="Vue" placement="bottom">
        <i className="devicon-vuejs-plain colored"></i>
      </LTooltip>
      </Grid>

      <Grid item>
      <LTooltip title="Angular" placement="bottom">
        <i className="devicon-angularjs-plain colored"></i>
      </LTooltip>
      </Grid>


      {/* <Grid item>
      <LTooltip title="C-sharp" placement="bottom">
        <i className="devicon-csharp-plain colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title=".NET CORE" placement="bottom">
        <i className="devicon-dotnetcore-plain colored"></i>
      </LTooltip>
      </Grid> */}


      <Grid item>
      <LTooltip title="MongoDB" placement="bottom">
        <i className="devicon-mongodb-plain colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Visual Studio" placement="bottom">
        <i className="devicon-visualstudio-plain colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="VScode" placement="bottom">
        <i className="devicon-vscode-plain colored"></i>
      </LTooltip>
      </Grid>
      {/* <Grid item>
      <LTooltip title="C-sharp" placement="bottom"></LTooltip>
        <i className="devicon-visualstudio-plain colored"></i>
      </Grid> */}
      <Grid item>
      <LTooltip title="GitHub" placement="bottom">
        <i className="devicon-github-original colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Firebase" placement="bottom">
        <i className="devicon-firebase-plain colored"></i>
      </LTooltip>
      </Grid>


      <Grid item>
      <LTooltip title="Material-UI" placement="bottom">
        <i className="devicon-materialui-plain colored"></i>
      </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Bootstrap" placement="bottom">
        <i className="devicon-bootstrap-plain colored"></i>
        </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Canva" placement="bottom">
        <i className="devicon-canva-original colored"></i>
        </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Adobe Xd" placement="bottom">
        <i className="devicon-xd-plain colored"></i>
        </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Photoshop" placement="bottom">
        <i className="devicon-photoshop-plain colored"></i>
        </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Illustrator" placement="bottom">
        <i className="devicon-illustrator-plain colored"></i>
        </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="After Effects" placement="bottom">
        <i className="devicon-aftereffects-plain colored"></i>
        </LTooltip>
      </Grid>
      <Grid item>
      <LTooltip title="Blender" placement="bottom">
        <i className="devicon-blender-original colored"></i>
        </LTooltip>
      </Grid>

    </Grid>
  )
}

export default Icons