import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Logo from '../img/logo/logo-light.svg'
// import Logo from '../img/logo/logo-white.svg'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'Calc(100vh - 50px)',
    paddingTop: '50px',
    color: '#fff'
  },

  load: {
    height: 'Calc(100vh - 50px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff'
  }

}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.load}>
      <div className='container'>

      <Grid container alignItems='center' justifyContent='center'>
        <Grid item xs={12} className="mb-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={Logo} alt="" className='logospin' />
        </Grid>
        <Grid item xs={12}  style={{ textAlign: 'center' }}>    
          <h1 style={{ fontSize: '5rem', marginBottom: '1rem' }}>404</h1>
          <h3 style={{ marginBottom: '2rem' }}>Det här är förmodligen inte sidan du söker!</h3>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Link to="/">
            <Button variant='contained' color='primary'> Tillbaka till startsidan</Button>
          </Link>
        </Grid>
      </Grid>
             
      </div>


    </div>
  )
}

export default NotFound